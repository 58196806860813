import React,{useEffect,useState} from 'react'
import Login from './modules/Components/Login/Login.jsx';
import Navigation from './modules/Navigation/Navigation.jsx';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom"
import SamlLogin from "./Saml-components/SamlLogin"
import Update from "./Saml-components/Update"
import MyFavourites from './modules/Components/MyFavourites/MyFavourites.jsx';
import ExclusiveDashboard from './modules/Components/ExclusiveDashboard/ExclusiveDashboard.jsx';
import ToolsHomePage from './modules/Components/ToolsHomePage/ToolsHomePage.jsx';
import { CONFIG } from './config/config.jsx';
import FocusTour from './modules/Components/FocusTour/FocusTour.jsx';
import LinkedInLogin from './LinkedInLogin/LinkedInLogin.jsx';
import LinkedInUpdate from './LinkedInLogin/LinkedIn-update.jsx';


function App() {

  const location = useLocation()
  
  const [Path, setPath] = useState('/navigation/my-favourites');

  useEffect(() => {
    
    if(location.pathname !== '/tour' && location.pathname !== '/')
      setPath(location.pathname); 
    
  }, [location.pathname])
  

  return (
    <>
      
       <Routes>
        <Route path="/" element={<Login></Login>}></Route>
        <Route path="/tour" element={<FocusTour Path={Path}></FocusTour>}></Route>
        <Route path="/navigation/" element={<Navigation></Navigation>}>
          <Route path='my-favourites' element={<MyFavourites />}></Route>
          <Route path='executive-dashboard' element={<ExclusiveDashboard />}></Route>
          <Route path='tools/post-event-promo-diagnosis-dashboards' element={<ToolsHomePage data={CONFIG.TOOL_HOME_PAGE_DATA[0]} />}></Route>
          <Route path='tools/promo-what-if' element={<ToolsHomePage data={CONFIG.TOOL_HOME_PAGE_DATA[1]} />}></Route>
          <Route path='tools/pricing-dashboard' element={<ToolsHomePage data={CONFIG.TOOL_HOME_PAGE_DATA[2]} />}></Route>
          <Route path='tools/pvp' element={<ToolsHomePage data={CONFIG.TOOL_HOME_PAGE_DATA[3]} />}></Route>
          <Route path='tools/ammps' element={<ToolsHomePage data={CONFIG.TOOL_HOME_PAGE_DATA[4]} />}></Route>
          <Route path='tools/assortment-prioritization' element={<ToolsHomePage data={CONFIG.TOOL_HOME_PAGE_DATA[5]} />}></Route>
          <Route path='tools/aos' element={<ToolsHomePage data={CONFIG.TOOL_HOME_PAGE_DATA[6]} />}></Route>
          <Route path='tools/asset-planogram-tool' element={<ToolsHomePage data={CONFIG.TOOL_HOME_PAGE_DATA[7]} />}></Route>
          <Route path='tools/activation-analytics' element={<ToolsHomePage data={CONFIG.TOOL_HOME_PAGE_DATA[8]} />}></Route>
          <Route path='tools/predictive-order' element={<ToolsHomePage data={CONFIG.TOOL_HOME_PAGE_DATA[9]} />}></Route>
          <Route path='tools/fable' element={<ToolsHomePage data={CONFIG.TOOL_HOME_PAGE_DATA[10]} />}></Route>
          <Route path='tools/Asset-placement-simulator' element={<ToolsHomePage data={CONFIG.TOOL_HOME_PAGE_DATA[11]} />}></Route>
          <Route path='tools/integrated-commercial-planner' element={<ToolsHomePage data={CONFIG.TOOL_HOME_PAGE_DATA[12]} />}></Route>
          <Route path='tools/target-group-builder' element={<ToolsHomePage data={CONFIG.TOOL_HOME_PAGE_DATA[13]} />}></Route>
          <Route path='tools/demand-planning' element={<ToolsHomePage data={CONFIG.TOOL_HOME_PAGE_DATA[14]} />}></Route>
          <Route path='tools/pos-initiative' element={<ToolsHomePage data={CONFIG.TOOL_HOME_PAGE_DATA[15]} />}></Route>
          <Route path='tools/market-share-tool' element={<ToolsHomePage data={CONFIG.TOOL_HOME_PAGE_DATA[16]} />}></Route>
          <Route path='tools/simulator-chatbot' element={<ToolsHomePage data={CONFIG.TOOL_HOME_PAGE_DATA[17]} />}></Route>
        </Route>
        <Route path="/saml-login" element={<SamlLogin></SamlLogin>}></Route>
          <Route path="/linkedin-login" element={<LinkedInLogin></LinkedInLogin>}></Route>
          <Route path="/update" element={<Update></Update>}></Route>
          <Route path="/linkedin-update" element={<LinkedInUpdate></LinkedInUpdate>}></Route>
       </Routes>
       
       
     </>
  )
}

export default App

