export const postData = async (data) => {
  const baseUrl = "https://hubble-be.azurewebsites.net/saveToken"
  let options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(data)
  }
  return fetch(baseUrl, options)
}

export const callApi = async (data) => {
  const baseUrl = "https://hubble-be.azurewebsites.net/usersDetails"
  let options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      'token': data
    },
  }
  return fetch(baseUrl, options)

}


export const getAccessCode = (outh_code) => {
  let baseUrl = `https://www.linkedin.com/oauth/v2/accessToken?response_type=code&grant_type=authorization_code&client_id=78mc87oyo019kw&client_secret=IDBjpg7nepPQSmBp&redirect_uri=https%3A%2F%2Fhubblestaging.azurewebsites.net%2Flinkedin-update&state=foobar&scope=r_liteprofile%20r_emailaddress%20w_member_social&code=${outh_code}`
  let options = {
    method: "GET",
    contentType: 'x-www-form-urlencoded',
    // headers: {
    //   "referer":"https://hubblestaging.azurewebsites.net/",
    //   "Access-Control-Allow-Origin": "*",
    //   "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
    //   "Access-Control-Allow-Headers":"Content-Type, Authorization, X-Requested-With"
    // },
    // body: {
    //   "grant_type": 'authorization_code',
    //   "code": outh_code,
    //   "state":"DCEeFWf45A53sdfKef424",
    //   "redirect_uri": 'https%3A%2F%2Fhubblestaging.azurewebsites.net%2Flinkedin-update',
    //   "client_id": '78mc87oyo019kw',
    //   "client_secret": 'IDBjpg7nepPQSmBp'
    // }
  }
  return fetch(baseUrl, options)
}

export const getLinkedInData = (access_code) => {
  const baseUrl = "https://api.linkedin.com/v2/me"
  let options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_code}`
    },
  }
  return fetch(baseUrl, options)

}


export const getLinkedInEmail = (access_code) => {
  const baseUrl = "https://api.linkedin.com/v2/emailAddress?q=members&projection=(elements*(handle~))"
  let options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_code}`
    },
  }
  return fetch(baseUrl, options)

}


export const updateLinkedinUsers = async (userData) => {
  const baseUrl = "http://localhost:9000/users"
  let options = {
    method: "POST",
    body: userData
  }
  return fetch(baseUrl, options)
}