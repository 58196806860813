import React from 'react'
import { Header, Icon } from 'semantic-ui-react';
import './TourModal.css'
import logo from '../../../Images/logo-Loading-new.gif'

function TourModal({ para1,para2,para3,setIndex,btn ,handleClick}) {

    return (
        <>
            {/* {showModal ? ( */}
                {/* <div className='background'> */}
                    <div className='modal'>
                        <div className='top-panel' style={{background:'linear-gradient(180deg, #01181D 0%, #003B3F 100%)'}}>
                            {/* <p className='skip-text' onClick={() => skipTour()}>Skip</p> */}
                            {/* <Icon name='close' className='closeModalIcon' size='big' onClick={() => skipTour()} /> */}
                            {/* <img src={IMAGECONFIG.TOURMODALLOGO} style={{ width: "100.3%",height:'11em' }} /> */}
                            {/* <Header as='h1' style={{color:'white',paddingBottom:'1em',paddingLeft:'2em',paddingRight:'2em',textAlign:'center',fontFamily:'Montserrat',fontSize:'3em'}}>
                               
                            </Header> */}
                            <img src={logo} style={{height:'7.8rem',paddingLeft:'34%'}}/>
                        </div>
                        <div className='middle-panel'>
                        <p style={{padding:'7% 0',margin:'0'}}>{para1}</p>
                        <p style={{marginBottom:'2em'}}>{para2}</p>
                        <p>{para3}</p>
                        </div>
                        <div className='bottom-panel'>
                            <Header as='h1' style={{ fontFamily: 'Montserrat', color: "#FFFFFF", fontWeight: 600, fontSize: "23px" }} onClick={handleClick}>
                                <span style={{cursor:'pointer'}}>{btn}</span>
                            </Header>
                        </div>
                    </div>
                 {/* </div> */}
            {/* ) : null} */}
        </>
    );
}

export default TourModal

