import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core'
import './Login.css'
import React from 'react'
import { IMAGECONFIG } from '../../../config/image-config';
import { useNavigate } from "react-router-dom";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

function Login() {
    const navigate = useNavigate()

    return (
        <div className='login'>
            <div className='top'>
                <img alt='hubble logo' src={IMAGECONFIG.HUBBLELOGO} style={{ maxWidth: "170px", margin: "20px" }} />
                <Typography variant="h1" gutterBottom style={{ margin: "100px 20px 0", width: "50%", fontWeight: 700, fontSize: "50px", color: "#FFFFFF", fontFamily: 'Montserrat' }}>
                    Revolutionizing the approach towards RGM Initiatives
                </Typography>
                <Typography variant="body2" gutterBottom style={{ margin: "20px", width: "50%", fontWeight: 500, fontSize: "16px", color: "rgba(255, 255, 255, 0.6)", fontFamily: 'Montserrat' }}>
                    Consisting of ready to deploy, ready to integrate and ready to deliver market relevant tools right at your finger tips
                </Typography>
            </div>
            {/* <div className='bottom'>
                <Typography variant='h3' gutterBottom style={{ margin: "20px", fontWeight: 400, fontSize: '25px', fontFamily: 'Montserrat' }}>
                    Login As
                </Typography>
                <Grid container spacing={1} style={{ marginTop: "10px" }}>
                    <Grid item xs={2}>
                        <Card className='login-card'>
                            <CardContent>
                                <div className='login-card-content'>
                                    <HighlightOffIcon style={{ alignSelf: "self-end" }} />
                                    <img alt='sample user' src={IMAGECONFIG.SAMPLE_USER_1} style={{ margin: "25px auto" }} />
                                    <Typography variant='h6' gutterBottom style={{ fontWeight: 600, fontSize: '15px', fontFamily: 'Montserrat', textAlign: "center" }}>
                                        John peter
                                    </Typography>
                                    <Typography variant='caption' gutterBottom style={{ fontWeight: 300, fontSize: '12px', fontFamily: 'Montserrat', color: "#595959", textAlign: "center" }}>
                                        Active 1 days ago
                                    </Typography>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div> */}
            <div className='login-form'>
                <Typography variant='h5' style={{ textAlign: "center", fontWeight: 600, fontSize: "30px", color: "#101828", fontFamily: 'Montserrat' }}>
                    Powerful, self-serve product and growth analytics
                </Typography>
                <iframe
                    title='Hubble Promo'
                    style={{ margin: "10px auto", border: "0px" }}
                    src="https://www.youtube.com/embed/EquLbxiJ_7s"
                    allowfullscreen="allowfullscreen"
                    width="100%"
                    height="280"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
                <div className='buttons' style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "10px" }}>
                    <Button style={{
                        background: "#00A5A5",
                        boxShadow: "0px 4px 19px rgba(0, 165, 165, 0.3)",
                        borderRadius: "10px",
                        fontFamily: 'Montserrat',
                        fontSize: "18px",
                        color: "white",
                        textTransform: "capitalize",
                        width: "100%",
                    }} variant="contained" size="large" onClick={() => navigate("/tour")} disableElevation>
                       Click to Start
                    </Button>
                    {/* <Button style={{
                        background: "#0067b4",
                        boxShadow: "0px 4px 19px rgba(0, 165, 165, 0.3)",
                        borderRadius: "10px",
                        fontFamily: 'Montserrat',
                        fontSize: "18px",
                        color: "white",
                        width: "100%",
                        marginLeft: "10px",
                        textTransform: "capitalize"
                    }} variant="contained" size="large" onClick={() => navigate("/linkedin-login")} startIcon={<img alt='linkeding logo' style={{ maxWidth: "25px" }} src={IMAGECONFIG.LINKEDINLOGO} />} disableElevation>
                        Sign In With LinkedIn
                    </Button> */}
                   </div>
                <Typography variant="subtitle2" style={{ textAlign: "center", color: "#667085" }}>
                    Join 4,000+ companies already growing
                </Typography>
                <div className='trusted-clients' style={{ display: "flex", justifyContent: "space-around", marginTop: "10px", alignItems: "center" }}>
                    <img alt='client image' src={IMAGECONFIG.TRUSTED_CLIENT_1} style={{ width: "100px", height: "30px" }} />
                    <img alt='client image' src={IMAGECONFIG.TRUSTED_CLIENT_2} style={{ width: "90px", height: "35px" }} />
                    <img alt='client image' src={IMAGECONFIG.TRUSTED_CLIENT_3} style={{ width: "100px", height: "40px" }} />
                    <img alt='client image' src={IMAGECONFIG.TRUSTED_CLIENT_4} style={{ width: "100px", height: "60px" }} />
                </div>
            </div>
        </div>
    )
}

export default Login