export const getCookie = (name) => {
  console.log(document.cookie)
    const ca = decodeURIComponent(document.cookie).split(";");
    const caLen = ca.length;
    const cookieName = `${name}=`;
    let c;
  
    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, "");
      console.log("c",c)
      if (c.indexOf(cookieName) === 0) {
        console.log("sub",c.substring(cookieName.length, c.length))
        return c.substring(cookieName.length, c.length);
      }
    }
    return "";
  };
  