import { Button, Drawer, TextField } from '@mui/material'
import React from 'react'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import './MyNotes.css'
import { Typography } from '@material-ui/core';

function MyNotes({ open, closeNotesPanel }) {
    return (
        <Drawer
            className='notes-drawer'
            sx={{
                width: '40vw',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: '40vw',
                },
            }}
            hideBackdrop={false}
            // variant="persistent"
            anchor="right"
            open={open}
        >
            <ArrowBackOutlinedIcon onClick={closeNotesPanel} style={{ cursor: "pointer" }} />
            <Typography variant="h6" style={{ fontWeight: 500, FontSize: "14px", fontFamily: 'Montserrat', color: "#2D2A2B", marginTop: "20px" }}>
                Add A New Note
            </Typography>
            <TextField
                multiline
                rows={20}
                sx={{
                    "& .MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                            borderColor: "rgba(237, 241, 244)"
                        }
                    },
                    "& .MuiOutlinedInput-root:hover": {
                        "& > fieldset": {
                          borderColor: "rgba(237, 241, 244)"
                        }
                      }
                }}
            />
            <Button variant="contained" size="large" style={{ width: "35%", background: "#00A5A5", marginTop: "10px", fontFamily: 'Montserrat', fontWeight: 600, fontSize: "16px" }}>
                Save Note
            </Button>
        </Drawer>
    )
}

export default MyNotes