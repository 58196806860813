import React, { useState, useEffect, useRef } from 'react';
import Slider_Data from './Slider_config';
import styled from 'styled-components';
import "./Caraousel.css";
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider, Typography } from '@mui/material';
import logo from '../../../Images/logo-Loading-new.gif'







const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'cursive',
    ].join(','),
    // fontSize: '2vw'
  },
});




const Background = styled.div`
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.1);
 backdrop-filter: blur(2.5px);
position: fixed;
z-index: 2;
// display: flex;
// align-items: center;
overflow: hidden;
  `;

const dotStyle = {
  margin: "0 3px",
  cursor: "pointer",
  fontSize: "20px",
};


const Caraousel = ({ Index, setIndex, skipTour }) => {


  const [position, setPosition] = useState(0);
  const indexRef = useRef(0);
  const [vari, setvari] = useState(0);
  // const pointerRef = useRef(0);

  let para1 = 'Hello John';
  let para2 = 'Welcome to Hubble';
  let para3 = 'Here is a quick tour to get you acquainted with its capabilities';

  let para4 = 'You got the basics !';
  let para5 = 'You can find these tips anytime using the Help Section!'

  let exitBtn = 'Explore Hubble';
  let startBtn = 'Lets Begin'

  const slides = Slider_Data.length;


  const backFunctionCall = () => {
    setvari(-1);
    setIndex(Index - 1);

  }

  const tourStart = () => {
    
    setvari(2)
    setIndex(Index + 1);
    setPosition(position - 100);
  }

  const gotoPrev = () => {
    const isFirstSlide = Index === 0;
    const newIndex = isFirstSlide ? 0 : Index - 1;
    const isLastSlide = Index === slides-1;
    const isSecondLastSlide = Index === slides-2;

    setIndex(newIndex);

    // setvari(0)
      
    

    isFirstSlide
      ? setPosition(0)
      
      : isLastSlide ? setPosition(position) : setPosition(position + 100);
  };

  const gotoNext = () => {
    const isLastSlide = Index === slides-1;
    const isSecondLastSlide = Index === slides-2;

    const newIndex =  Index + 1;

    // pointerRef.current += 1;

    setIndex(newIndex);

     isSecondLastSlide ? setPosition(position) : setPosition(position - 100);
    // setPosition(position - 100);

    if(Index === slides - 2){
      setvari(1)
    }

    console.log('index is ' + Index)

  }


  const handleClick = (slideIndex) => {

    indexRef.current = slideIndex;
    setIndex(slideIndex)
    
     setPosition(indexRef.current * (-100));

  }

  const handleClick2 = () => {

    setIndex(0);
  }



  return (

    <Background >
      {/* {(Index === slides) ? <ExitModal skipTour={skipTour} /> : ( */}
      <>

        

          <div className='caraousel-content'>
            {/* <div className="arrow-left" onClick={gotoPrev} style={{display : Index === 0 ? 'none' : 'block'}}>
            <Icon name='arrow left' size='large' style={{ cursor: "pointer", color: position === 0 ? "grey" : "white", position:'absolute',top:'11px',left:'10px'}} />
              </div> */}

            {/* <div className={ Index === 0 ? 'arrows-startindex' : 'arrows'}>
              <div className="arrow-left" onClick={gotoPrev} style={{ display: Index === 0 ? 'none' : 'block', zIndex: '1' }}>
                <Icon name='arrow left' size='large' style={{ cursor: "pointer", color: position === 0 ? "grey" : "white",position:'relative',top:'20%',left:'16%'}} />
              </div>

              <div className="arrow-right" onClick={gotoNext} style={{ display: Index === slides - 1 ? 'none' : 'block', zIndex: '1' }}>
                <Icon name='arrow right' size='large' style={{ cursor: "pointer", color: "white",position:'relative',top:'20%',left:'26%'}} />
              </div>

            </div> */}


            {/* {Index === -1 ?
              <TourModal handleClick={handleClick2} para1={para1} para2={para2} para3={para3} btn={startBtn} />
              : */}
              
              {/* Index === slides ? <TourModal para1={para4} para3={para5} handleClick={skipTour} btn={exitBtn}/> : */}

              <div className={'left-part'} id='left' vari={vari} >

                { Index === slides - 1 ? 

                  <>

                   <div className='skip-btn' style={{ width: '0',fontFamily: 'Montserrat' }}>

                   <Button style={{ color: 'white',fontFamily: 'Montserrat',fontSize:'0.65rem' }} variant='text' onClick={backFunctionCall}>Back</Button>

                 </div> 

                 {/* <div className='endLogo'>

                 <img src={logo} style={{height:'7.8rem'}}/>

                 </div> */}
                 </>
                  :
                  
                   <div className='skip-btn'>

                    <Button style={{ color: 'white',fontFamily: 'Montserrat',fontSize:'0.65rem' }} variant='text' onClick={() => skipTour()}>Skip Welcome Tour</Button>

                  </div>
                }
                {Index === slides-1 ? 
                
                <div className='endLogo'>

                 <img src={logo} style={{height:'7.8rem'}}/>

                 </div>
                
                : ''}
                
                {Slider_Data.map((data, ind) => {
                  return (
                    <div className='data-content' style={{ transform: `translateX(${position}%)`, transition: '0.4s ease-out' }} vari={vari}>

                      <div className='header-tour' style={{ color: '#00a5a5', paddingBottom: '8%', maxWidth: '100%' }}>
                        <h1 className='header-content' >{data.header}</h1>
                      </div>
                      <div className={Index === 0 ? 'content-tour-init' :'content-tour'} style={{ color: 'white', width: '94%' }}>
                        <ThemeProvider theme={theme}>
                          <Typography>
                            {data.content}
                          </Typography>
                        </ThemeProvider>
                      </div>

                    </div>
                  )
                })}
                <div className={'left-footer'}>
                  {Index !== slides - 1 && Index !== 0 ?
                  <>

                    <button className={Index === 1 ? 'carousel-buttons disabled' : 'carousel-buttons'} onClick={gotoPrev}>Prev</button>


                    <div className='dots'>
                      {Slider_Data.map((slide, slideIndex) => (
                        slideIndex !== slides - 1 && slideIndex !== 0 ?
                        <div
                          key={slideIndex}
                          className={Index === slideIndex ? 'dot-ele-active' : 'dot-ele'}
                          onClick={() => handleClick(slideIndex)}
                        >
                        </div>
                        : ''
                      ))}
                    </div>

                    {/* {Index !== slides - 1 ? <button className={'buttons'} onClick={gotoNext}>Next</button> : <button className={'buttons'} onClick={() => skipTour()}>Start</button>}
                     */}
                    <button className={'carousel-buttons'} onClick={gotoNext}>Next</button>
                  </>
                 : <div className={Index === 0 ? '' : 'hubble-exit-btn'} vari={vari}>
                  {Index === 0 ? <button className='hubble-btn'  onClick={tourStart}>Let's Begin</button> : <button className='hubble-btn'  onClick={() => skipTour()}>Explore Hubble</button>} 
                  </div> 
                     }
                </div>
              </div>

            {/* } */}


            <div className={'right-part'} style={{ display: 'flex', overflow: 'hidden' }} vari={vari} >

              {/* <img src={Slider_Data[Index].image} style={{height:'40rem', maxWidth:'100%'}}/> */}
              {/* <video src={Slider_Data[Index].image} width='925' height='560' autoPlay loop>
                            
                    </video> */}

              {/* <div className="arrow-right" onClick={gotoNext} style={{ display: Index === slides - 1 ? 'none' : 'block', zIndex: '1' }}>
                    <Icon name='arrow right' size='large' style={{ cursor: "pointer", color: "white", position: 'absolute', top: '11px', left: '10px' }} />
                  </div> */}

              {Slider_Data.map((data, index) => {
                return (
                  <>
                    <video src={data.image} autoPlay loop muted style={{ transform: `translateX(${position}%)`, transition: '0.4s ease-out', minWidth: '100%', objectFit: 'fill' }}>
                    </video>

                  </>
                )
              })}
              {/* <div className="arrow-right" onClick={gotoNext} style={{display : Index === slides - 1 ? 'none' : 'block'}}>
                <Icon name='arrow right' size='large' style={{ cursor: "pointer", color: "white", position:'absolute',top:'11px',left:'10px'}} />
                  </div> */}
            </div>
            {/* <div className="arrow-right" onClick={gotoNext} style={{display : Index === slides - 1 ? 'none' : 'block'}}>
            <Icon name='arrow right' size='large' style={{ cursor: "pointer", color: "white", position:'absolute',top:'11px',left:'10px'}} />
              </div> */}
          </div>
    

      </>
      {/* ) */}
      {/* } */}

    </Background>
  )
}

export default Caraousel;