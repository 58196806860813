import React, { useEffect, useState } from 'react'
import "./Navigation.css"
import { NavLink, Outlet, useLocation } from "react-router-dom";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import GridViewIcon from '@mui/icons-material/GridView';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Collapse, ListItem, ListItemIcon, List, Typography, OutlinedInput, Divider } from '@material-ui/core';
import { IMAGECONFIG } from '../../config/image-config';
import SearchIcon from '@mui/icons-material/Search';
import TOOLS_LIST from './Navigation-config';
import { setToolData } from '../../redux/actions/tool-action';
import { getPermission, getComingFrom, setComingFrom, setPermission } from "../../utils/services"
import { getCookie } from '../../utils/getCookie'
import { useNavigate } from "react-router-dom"
import { FormControl, InputAdornment } from '@mui/material';
import { connect } from 'react-redux';
import {postData,callApi} from "../../utils/dataServices"
import { keys } from '@material-ui/core/styles/createBreakpoints';
import HelpIcon from '@mui/icons-material/Help';
import Help from '@mui/icons-material/Help';
import Help_list from './Help-config';





const Navigation = ({ tool_data }) => {
  const navigate = useNavigate();
  
  const [open, setOpen] = useState(true);
  const [openIndex, setOpenIndex] = useState(-1);
  const [help,setHelp] = useState(false);

  
  const toggleNavbar = () => {
    setOpen(!open);
    if (openIndex !== -1) {
      setOpenIndex(-1)
    }
  };

  const helpClick = () => {
    window.scrollTo(0,0);

    navigate('/tour');
  }



  // useEffect(() => {
  //   let comingFrom = getComingFrom() 
  //   if (comingFrom === "" || !comingFrom) {
  //     setComingFrom("navigation");
  //     // console.log("getComingFrom", getComingFrom(),"cookie",getCookie("hubble_access_token"))
  //     // if(getCookie("hubble_access_token") && !getCookie("hubble_access_token_via_linkedin")){
  //       navigate("/saml-login"); 
  //     // }else if(getCookie("hubble_access_token_via_linkedin") && !getCookie("hubble_access_token")){
  //     //   navigate("/linkedin_login"); 
  //     // }else{
  //     //   logout();
  //     // }
  //   }
  //   console.log("set")
  //   console.log(comingFrom)
  // }, [])


  let activeClass = {
    color: "#3DD5C8",
    fontWeight: 400
  };

  const toggleOpenIndex = (index) => {
    if (openIndex !== index) {
      setOpenIndex(index)
    } else {
      setOpenIndex(-1)
    }
  }


  const logout = () => {
    document.cookie = `hubble_access_token=;domain=.decisionpoint.in` + ";path=/";
    document.cookie = `hubble_first_name=;domain=.decisionpoint.in` + ";path=/";
    document.cookie = `hubble_expiry=;domain=.decisionpoint.in` + ";path=/";
    document.cookie = `hubble_username=;domain=.decisionpoint.in` + ";path=/";
    localStorage.setItem("hubble_username", "")
    localStorage.setItem("hubble_accessToken", "")
    localStorage.setItem("hubble_expiry", "")
    localStorage.setItem("hubble_first_name", "")
    navigate("/")
  }

  return (
    <div className="main-content">
      <div className='sidebar' style={{ width: open ? "20vw" : "4.5vw", minWidth: "50px" }}>
        <div className='top-section' style={open ? { display: "flex", justifyContent: "space-around" } : { marginLeft: "-2px" }}>
          <div className='logo'><img onClick={() => navigate("/navigation/my-favourites")} src={open ? IMAGECONFIG.HUBBLELOGO : IMAGECONFIG.LOGOWITHOUTTEXT} style={{ maxWidth: "150px" }} /></div>
        </div>
        <div className='list' style={{ marginTop: open ? '0px' : '50px' }}>
          {open ? <FormControl className='search-field' sx={{
            m: 1, width: '95%', "& .MuiOutlinedInput-root.Mui-focused": {
              "& > fieldset": {
                borderColor: "rgba(237, 241, 244)",
                borderRadius: "15px"
              }
            }
          }} variant="outlined">
            <OutlinedInput
            className='nav'
              id="outlined-adornment-weight"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "white" }} />
                </InputAdornment>
              }
              variant="standard"
              color="warning"
              aria-describedby="outlined-weight-helper-text"
            /> </FormControl> : null}
          <Typography variant="caption" display="block" gutterBottom style={{ color: '#FFFFFF', opacity: 0.6, display: open ? 'block' : 'none', fontFamily: 'Montserrat' }}>
            Menu
          </Typography>
          <NavLink
            to='my-favourites'
            style={({ isActive }) => (isActive ? activeClass : undefined)}
          >
            <BookmarksOutlinedIcon

              edge="start"
              fontSize='small'
              color="#FFFFFF"
              sx={{
                ".active &": {
                  color: "#3DD5C8",
                }
              }}
            />
            <div className='name' style={{ display: open ? 'block' : 'none'}}>My Favourites</div>
          </NavLink>
          <NavLink
            to='/navigation/executive-dashboard'
            style={({ isActive }) => (isActive ? activeClass : undefined)}
          >
            <GridViewIcon
              fontSize='small'
              edge="start"
              color="#FFFFFF"
              sx={{
                ".active &": {
                  color: "#3DD5C8",
                }
              }}
            />
            <div className='name name-execute' style={{ display: open ? 'block' : 'none' }}> Executive Dashboard</div>
          </NavLink>
          <Divider />
          <Typography variant="caption" display="block" gutterBottom style={{ color: '#FFFFFF', opacity: 0.6, display: open ? 'block' : 'none', fontFamily: 'Montserrat' }}>
            Tools by Category
          </Typography>
          <List>
            {TOOLS_LIST.map((tool, i) => {
              return <div key={i}>
                <ListItem className='list-item' onClick={() => toggleOpenIndex(i)}>
                  <div className='item-text'>
                    <ListItemIcon
                      onClick={!open && toggleNavbar}
                      className='link-logo'
                      edge="start"
                      sx={{
                        ".active &": {
                          color: "#3DD5C8",
                        }
                      }}>
                      {tool.icon}
                    </ListItemIcon>
                    <div className='name' style={{ display: open ? 'block' : 'none' }}>
                      {tool.title}
                    </div>
                  </div>
                  {openIndex === i ? <ExpandLessIcon style={{ display: open ? 'block' : 'none' }} /> : <ExpandMoreOutlinedIcon style={{ display: open ? 'block' : 'none' }} />}
                </ListItem>
                <Collapse className='collapse-list' in={i === openIndex} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {tool.items.map((item, k) => {
                      return <NavLink key={k} style={({ isActive }) => (isActive ? activeClass : undefined)} to={`/navigation/tools${item.to}`}>
                        <div className='name' style={{ display: open ? 'block' : 'none', fontSize: "13px" }}>{item.title}</div>
                      </NavLink>
                    })}
                  </List>
                </Collapse>
              </div>
            })}
          </List>
          <Divider />
          <Typography variant="caption" display="block" gutterBottom style={{ color: '#FFFFFF', opacity: 0.6, display: open ? 'block' : 'none', fontFamily: 'Montserrat' }}>
            Quick Links
          </Typography>          
          <a
            style={{ cursor: "pointer" }}
            onClick={() => setHelp((prev) => prev ? false:true)}
          >
              {Help_list.icon}
                <div style={{ display: open ? 'flex' : 'none',width:'100%',justifyContent:'space-between' }}>{Help_list.title} 
                {help ? <ExpandLessIcon style={{ display: open ? 'block' : 'none' }} /> : <ExpandMoreOutlinedIcon style={{ display: open ? 'block' : 'none' }} />}
              </div>
         </a>   
            <Collapse className='collapse-list' in={help} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                   
                  <div style={{ display: open ? 'block' : 'none', fontSize: "13px",paddingTop:"10px", paddingBottom:"10px", cursor:'pointer' }}>
                      {Help_list.items[0].title}
                  </div>  
                  <div style={{ display: open ? 'block' : 'none', fontSize: "13px",paddingTop:"10px", paddingBottom:"10px", cursor:'pointer' }}
                    onClick={helpClick}
                  >
                      {Help_list.items[1].title}
                  </div>   
                  </List>
              </Collapse>
          <a
            style={{ cursor: "pointer" }}
            onClick={() => logout()}
          >
            <img src={IMAGECONFIG.LOGOUT} />
            <div className='name' style={{ display: open ? 'block' : 'none', marginLeft: "10px" }}>Logout</div>
            
          </a>
        </div>
      </div>
      <div className='icons' style={{ top: "7.5%", left: open ? '18%' : '2%' }}>{open ? <KeyboardArrowLeftIcon fontSize='large' onClick={toggleNavbar} /> : <KeyboardArrowRightIcon fontSize='large' onClick={toggleNavbar} />}</div>
      <div className='content' style={{ width: open ? "80vw" : "95.5vw" }}>
        <div className='static-navbar' style={{ width: open ? "80vw" : "95.5vw" }}>
          <div className='tool-data'>
            {tool_data.segment && tool_data.tool_name ? <div className='tool-first-row'>
              <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 400, fontSize: "14px", color: "#666666", fontFamily: 'Montserrat' }}>
                All Tools / {tool_data.segment}
              </Typography>
              <Typography variant="h5" display="block" gutterBottom style={{ fontWeight: 600, fontSize: "20px", color: "#2D2A2B", fontFamily: 'Montserrat' }}>
                {tool_data.tool_name}
              </Typography>
            </div> : null}
          </div>
          <img style={{ cursor: "pointer" }} onClick={() => window.open('https://decisionpoint.ai/')} src={IMAGECONFIG.LOGO} alt='dp-logo' />
        </div>
        <Outlet />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  let { tool_data } = state.ToolReducerState
  console.log(tool_data)
  return ({
    tool_data: tool_data
  })
}

export default connect(mapStateToProps, { setToolData })(Navigation)