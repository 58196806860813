import React, { useEffect } from "react"
import { getCookie } from "../utils/getCookie"
import { useNavigate } from "react-router-dom"
import { postData, callApi } from "../utils/dataServices"
import { setPermission, setComingFrom, getPermission, getComingFrom } from "../utils/services"
import CircularProgress from '@mui/material/CircularProgress';

function Login() {
  const navigate = useNavigate()
  const access_token = getCookie("hubble_access_token");
  useEffect(() => {
    if (!getCookie("hubble_access_token")) {
      login()
    }
    else {
      callApi(getCookie("hubble_access_token")).then(res => {
        navigate("/tour");
      }).catch(err => {
        console.log("error message", err.message)
        navigate("/");
      })
    }
    console.log("token", getCookie("hubble_access_token"))
  }, [])

  const login = () => {
    let saml_login = process.env.REACT_APP_SAML_ENDPOINT
    window.location.href = saml_login + "/authenticate"
    console.log("moved to login")
  }
  return <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
    <div>
      <CircularProgress />
      <h4>loading..</h4>
    </div>
  </div>
}

export default Login