import React from "react";


const Slider_Data = [
    

    {
        "header":'',
        "content":[<p style={{fontSize:'2rem',color: '#00a5a5',fontWeight:600}} >Welcome to Hubble!</p>,<p>Here is a quick tour to get you acquainted with its capabilities</p>]
    },
    {
        "header": 'TRANSFORMING\nRGM',
        "content": [<p style={{fontSize:'20px'}}>NEXT-GEN PLATFORM </p>],
        "image": require('../../../Images/RGM_Hubble_logo-2.mp4')
    },
    {
        "header": 'Comprehensive\nRGM',
        "content": [<p style={{fontSize:'20px'}}>A set of <span className="blue-text"> 18 tools </span>under one platform for your complete <span className="blue-text"> RGM journey</span></p>],
        "proposition": ["Connected Tools"],
        "image": require('../../../Images/index-2.mp4')
    },
    {
        "header": "Advanced\nDashboard",
        // "content": [<p>Track the Output of your<span className="blue-text"> RGM Plans</span> at a single view.</p>,<p>Know the performance of your <span className="blue-text">business building blocks</span> along with factors impacting growth</p>],
        "content":[<p style={{fontSize:'20px'}}>Track the Output and Performance of your<span className="blue-text"> Business</span> at a single view.</p>],
        "proposition": ["Explore, Accelerate, Transform","Growth Algorithm "],
         "image": require('../../../Images/Ex-dash-3.mp4')
        
    },
    {
        "header": 'Shared\nVision', //intentional spacing given to make content into seperate lines
        "content": [<p style={{fontSize:'20px'}}><span className="blue-text">View, share and collaborate</span> your business plans all from one place</p>],
        "image": require('../../../Images/shared_vision-3.mp4'),
        "proposition":["Collaboration"]
    },
    {
        "header": "Personalised\nViews",
        "content": [<p style={{fontSize:'20px'}}>Personalized to <span className="blue-text">suit your individual needs</span> with customizable widgets</p>],
        "proposition": ["Flexible Dashboard"],

        "image": require('../../../Images/kpi_clear-1.mp4') 
    },
    {
        "header": "Alerts for\nAction", //intentional spacing given to make content into seperate lines
        "content": [<p style={{fontSize:'20px'}}>With <span className="blue-text">Active Alerts</span> take <span className="blue-text">instant business decisions</span></p>],
        "proposition": ["Alerts & Insights"],

        "image": require('../../../Images/act_alrt-1.mp4') 
    },
    {
        "header": "Interaction with\nMS Teams",
        "content": [<p style={{fontSize:'20px'}}>Track, interact and create scenarios powered by<span className="blue-text"> Beagle</span></p>],
        "proposition": ["Collaborative Chatbot"],
        "image": require('../../../Images/beagle-2-final.mp4') 
    },
    {
        "header": 'Tools Across your\nRGM Journey',
        "content": [<p></p>],
        "image": require('../../../Images/exit-page-RGM-Hubble-3.mp4')
    },
    {
        "header": <p style={{fontSize:'35px'}}>You got the basics!</p>,
        "content": [<p>You can find these tips anytime using the Help Section!</p>],
    }
    
]

export default Slider_Data;