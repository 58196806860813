import { CircularProgress } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import { getAccessCode, getLinkedInData, getLinkedInEmail, updateLinkedinUsers } from '../utils/dataServices'

function LinkedInUpdate() {
    const navigate = useNavigate()

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        let code = ''
        code = queryParams.get("code")
        if (code !== '') {
            let now = new Date();
            let time = now.getTime();
            let expireTime = time + 1000 * 36000;
            now.setTime(expireTime);
            //     setPost(response.data);
            //   });
            // getAccessCode(code).then((result) => {
             axios.get(`https://www.linkedin.com/oauth/v2/accessToken?response_type=code&grant_type=authorization_code&client_id=78mc87oyo019kw&client_secret=IDBjpg7nepPQSmBp&redirect_uri=https%3A%2F%2Fhubble-staging.decisionpoint.in%2Flinkedin-update&state=foobar&scope=r_liteprofile%20r_emailaddress%20w_member_social&code=${code}`).then((response)=>{
                console.log("response", response.data)
                let data = response.data;
                // AQUFFsdFHh8pV5CUBOolpZL933wy6-NUmwT8xdzHv58pILuO9TnllonYCIssNKF05OnijeJxJVEP8xsSg_4XPi-Dz7yWVixuea5fAmunWJVfIC_Sm4kqvlNTL7ZiCfKzxWILDZGLDcxhI7PWEpU2x_kol3P9VLFqExqs5NZYckimR3umZuttMjTs7IWNlvFX-7t_sM8paOk_-TSbkR0EFVfK1KGqwk0MBrwCdUExiFy5t8jOuQfgEIDuQWASykBmbeaNsvIQNoU0iYEAqaGNgzzJT0xq70dpyZYTRyUg8xUC-8v2wCArAkJdtWVlDyNJLzEMcINLuRXNS2bpRu5hFZFD7sHtqA
                    let config = { headers: { Authorization: `Bearer ${data.access_token}` } }
                    Promise.all([axios.get('https://api.linkedin.com/v2/me', config), axios.get('https://api.linkedin.com/v2/emailAddress?q=members&projection=(elements*(handle~))', config)]).then((resArr) => {
                        document.cookie = `hubble_access_token_via_linkedin=${code};expires=${now.toUTCString()}; path=/`
                        console.log(resArr)
                        let propertyObj = {
                            id: resArr[0].id,
                            firstName: resArr[0].firstName.localized.en_US,
                            lastName: resArr[0].lastName.localized.en_US,
                            emailAddress: resArr[1].elements[0]['handle~'].emailAddress
                    }
                        console.log(propertyObj)
                        updateLinkedinUsers(propertyObj).then(navigate('tour'))
                    })
            })
            // .catch((err)=>{
            //     console.error(err)
            //     navigate('/')
            // })


        } else {
            navigate('/')
        }
    }, [])

    return <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div>
            <CircularProgress />
            <h4>Logging You In</h4>
        </div>
    </div>
}

export default LinkedInUpdate