import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import CircularProgress from '@mui/material/CircularProgress';
import { getCookie } from '../utils/getCookie';
import { CONFIG } from '../config/config';

function LinkedInLogin() {
        useEffect(() => {
            login()
        }, [])
      
        const login = () => {
          let {clientId,redirectUri,oauthUrl,scope,state} = CONFIG.LinkedInApi
          let linkedin_login = `${oauthUrl}&client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUri}`;
          console.log(linkedin_login)
          window.location.href = linkedin_login
          console.log("moved to login")
        }

    return <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div>
            <CircularProgress />
            <h4>loading..</h4>
        </div>
    </div>
}

export default LinkedInLogin