import { Card, CardContent, Grid, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MyNotes from '../MyNotes/MyNotes';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from "highcharts/highcharts-more";
import React, { useEffect, useState } from 'react'
import { CONFIG } from '../../../config/config';
import './ExclusiveDashboard.css'
import { IMAGECONFIG } from '../../../config/image-config';
import { Divider, Popup, Progress } from 'semantic-ui-react';



HighchartsMore(Highcharts);

function ExclusiveDashboard() {

    const [dataIndex, setDataIndex] = useState(1);
    const [sharedDataIndex, setSharedDataIndex] = useState(0);
    const [notesPanes, setNotesPanel] = useState(false);
    const [waterfallChartOptions, setWaterfallChartOptions] = useState([]);

   
    const [flg,setflg] = useState(false);

    useEffect(() => {
        updateChartConfig()
        setflg(true);

    }, [])

    const closeNotesPanel = () => {
        setNotesPanel(false)
    }

    const updateChartConfig = () => {
        let chart_options = waterfallChartConfig();
        setWaterfallChartOptions(chart_options)
    }

    const getApporpriateArrow = (value) => {
        if (value > 0) {
            return `<span class="testFont">&#8593;</span>`
        } else return `<span class="testFont">&#8595;</span>`
    }

    const waterfallChartConfig = () => {
        let chart_config_options = [];

        chart_config_options.push({
            chart: {
                type: 'waterfall',
                marginTop: 50,
                height: 450
            },

            title: {
                text: 'GROWTH ALGORITHM',
                align: 'left',
                x: 5,
                verticalAlign: 'top',
                y: 5,
                margin: 0,
                style: CONFIG.CHART_TITLE_STYLE,
            },
            xAxis: {
                type: 'category',
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                        color: 'rgba(96, 96, 96, 0.9)'
                    }
                }
            },

            yAxis: {
                visible: false,
                maxPadding: 0.2,
                title: {
                    enabled: false
                }
            },
            legend: {
                enabled: false
            },

            tooltip: {
                shared: false,
                style: CONFIG.DATALABEL_STYLE,
                pointFormatter: function () {
                    return this.category === 0 ? null : (
                        'LY Growth%' + ' : ' + '<b>' + this.options.LY_Growth + '%</b><br/>' +
                        'Plan (In Mn)' + ' : ' + getApporpriateArrow(this.options.Plan) + '<b>' + this.options.Plan + '</b><br/>' +
                        'Plan Growth %' + ' : ' + getApporpriateArrow(this.options.Plan_Growth) + '<b>' + this.options.Plan_Growth + '%</b><br/>' +
                        'Predicted Growth %' + ' : <b>' + this.options.Predicted_Growth + '%</b><br/>'
                    );
                },
            },
            plotOptions: {
                series: {
                    borderColor: 'white'
                }
            },
            credits: {
                enabled: false
            },
            series: [{
                upColor: CONFIG.SEIRES_COLOR[0],
                color: CONFIG.SEIRES_COLOR[1],
                data: [{
                    name: 'Revenue 2021',
                    y: 98,
                    color: 'rgb(166 166 166 / 70%)'
                }, {
                    name: 'Price Correction',
                    y: 9.8,
                    percentage: "10%",
                    LY_Growth: 10,
                    Plan: 11.8,
                    Plan_Growth: 12,
                    Predicted_Growth: 13.3
                }, {
                    name: 'Discounts',
                    y: -3.9,
                    percentage: "-4%",
                    LY_Growth: -5.0,
                    Plan: -3.4,
                    Plan_Growth: -3.5,
                    Predicted_Growth: -4.7
                }, {
                    name: 'Media Spends',
                    y: -2.9,
                    percentage: "-3%",
                    LY_Growth: -1.5,
                    Plan: -1,
                    Plan_Growth: -1,
                    Predicted_Growth: -0.8
                }, {
                    name: 'Product Range',
                    y: 6.9,
                    percentage: "7%",
                    LY_Growth: 6,
                    Plan: 7.3,
                    Plan_Growth: 7.5,
                    Predicted_Growth: 9.3
                }, {
                    name: 'Distribution',
                    y: 5.9,
                    percentage: "6%",
                    LY_Growth: 6,
                    Plan: 7.8,
                    Plan_Growth: 8,
                    Predicted_Growth: 8
                }, {
                    name: 'Supply Efficiency',
                    y: 4.9,
                    percentage: "5%",
                    LY_Growth: 5,
                    Plan: 8.3,
                    Plan_Growth: 8.5,
                    Predicted_Growth: 6.7
                }, {
                    name: 'Revenue 2022',
                    isSum: true,
                    color: "rgba(75, 75, 75, 0.7)",
                    LY_Growth: 20.5,
                    Plan: 128.8,
                    Plan_Growth: 31.5,
                    Predicted_Growth: 31.9
                }],
                dataLabels: {
                    enabled: true,
                    verticalAlign: 'top',
                    align: 'center',
                    x: 3,
                    y: -30,
                    formatter: function () {
                        return this.point.category === 0 || this.point.category === 7 ? this.y : this.percentage
                    },
                    style: CONFIG.DATALABEL_STYLE,
                },
                pointPadding: 0
            }]
        })

        return chart_config_options
    }


    const getTableCellData = (value, index) => {
        if (index === 0) {
            return <TableCell key={index} className='table-cell first-cell' style={{ textDecoration: "underline" }}>
                {value}
            </TableCell>
        } else if (value === "#") {
            return <TableCell key={index} className='table-cell data-cell'><Link style={{ textDecoration: "underline", color: "#2D2A2B", fontWeight: 600, fontSize: "12px", cursor: "pointer" }} to={value}>View Result</Link></TableCell>
        } else if (index === 3) {
            return <TableCell key={index} className='table-cell data-cell'>
                <div className='flex-cell' style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat' }}>{value}</Typography>
                    <div className='arrow-flex' style={{ display: "flex", alignItems: "center" }}>
                        {Number(CONFIG.MY_SCENARIOS_DATA[dataIndex].data.impact[index]) > 1 ? <ArrowUpwardIcon style={{ fontWeight: 700, color: "#0ABB3C" }} /> : <ArrowDownwardIcon style={{ fontWeight: 700, color: "#FF3D3D" }} />}
                        <Typography variant="body1" style={{ fontWeight: 600, color: Number(CONFIG.MY_SCENARIOS_DATA[dataIndex].data.impact[index]) > 1 ? '#0ABB3C' : '#FF3D3D', fontFamily: 'Montserrat' }}>{CONFIG.MY_SCENARIOS_DATA[dataIndex].data.impact[index]}</Typography>
                    </div>
                </div>
            </TableCell>
        } else {
            return <TableCell key={index} className='table-cell data-cell' style={{ fontFamily: 'Montserrat' }}>{value}</TableCell>
        }
    }

    const getTableCellDataForSharedScenarios = (value, index) => {
        if (index === 0) {
            return <TableCell key={index} className='table-cell first-cell' style={{ textDecoration: "underline" }}>
                {value}
            </TableCell>
        } else if (value === "#") {
            return <TableCell key={index} className='table-cell data-cell'><Link style={{ textDecoration: "underline", color: "#2D2A2B", fontWeight: 600, fontSize: "12px", cursor: "pointer" }} to={value}>View Result</Link></TableCell>
        } else if (index === 3) {
            return <TableCell key={index} className='table-cell data-cell'>
                <div className='flex-cell' style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                    <Typography variant="body1">{value}</Typography>
                    <div className='arrow-flex' style={{ display: "flex", alignItems: "center" }}>
                        {Number(CONFIG.MY_SCENARIOS_DATA[sharedDataIndex].data.impact[index]) > 1 ? <ArrowUpwardIcon style={{ fontWeight: 700, color: "#0ABB3C" }} /> : <ArrowDownwardIcon style={{ fontWeight: 700, color: "#FF3D3D" }} />}
                        <Typography variant="body1" style={{ fontWeight: 600, color: Number(CONFIG.MY_SCENARIOS_DATA[sharedDataIndex].data.impact[index]) > 1 ? '#0ABB3C' : '#FF3D3D' }}>{CONFIG.MY_SCENARIOS_DATA[dataIndex].data.impact[index]}</Typography>
                    </div>
                </div>
            </TableCell>
        } else {
            return <TableCell key={index} className='table-cell data-cell'>{value}</TableCell>
        }
    }

    return (
        <div className='executive-dashboard' style={{ background: "rgba(248, 248, 248, 1)"}}>
           
            <div className='top-section-dashboard'>
                <div className='component'>
                    <div className='header'>
                        <div className='my-notes-and-beagle'>
                            <div className='beagle' onClick={() => window.open('https://teams.microsoft.com/l/app/a0f836a9-178e-4407-9c26-4579dcd17bd1?source=app-details-dialog')}>
                                <img src={IMAGECONFIG.TEAMSICON} style={{ marginRight: "10px", height: "20px", width: "20px", marginBottom: "5px" }} />
                                <Typography variant="caption" gutterBottom style={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: "14px" }}>
                                    Converse using Teams
                                </Typography>
                            </div>
                            <div className='notes' onClick={() => setNotesPanel(true)}>
                                <EditOutlinedIcon style={{ color: "#3DD5C8", marginRight: "5px", marginBottom: "5px" }} />
                                <Typography variant="caption" gutterBottom style={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: "14px" }}>
                                    My Notes
                                </Typography>
                            </div>
                        </div>
                        <Typography variant="h4" style={{ fontWeight: 600, fontFamily: 'Montserrat' }}>
                            Executive Dashboard Summary
                        </Typography>
                        <Typography variant="body1" gutterBottom style={{ color: '#595959', fontFamily: 'Montserrat' }}>
                            Have a look at the latest KPIs and scenario performance
                        </Typography>
                    </div>
                    <div className='rgm-equations'>
                        <Typography variant="h5" style={{ fontWeight: 600, fontFamily: 'Montserrat' }}>
                            RGM Equation
                        </Typography>
                        <div className='rgm-modules'>
                            <Card className='rgm-module'>
                                <CardContent className='card-content'>
                                    <div className='content-flex ' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', color: "#101828", fontSize: "16px" }}>
                                            Value
                                        </Typography>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', color: "##101828", fontSize: "17px" }}>
                                            2.3M
                                        </Typography>
                                    </div>
                                    <div className='content-flex exe-percent' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography variant="h6"  gutterBottom style={{ color: '#027A48', fontFamily: 'Montserrat', fontWeight: 600, fontSize: "36px" }}>
                                           5%
                                        </Typography>
                                        <img src={IMAGECONFIG.VALUEPNG} />
                                    </div>
                                    <div className='content-flex exe-percent1' style={{ display: "flex", alignItems: "baseline", marginTop: "10px" }}>
                                        <Typography variant="body1" gutterBottom style={{ color: '#101828', fontFamily: 'Montserrat', fontWeight: 500, fontSize: "14px" }}>
                                            2%
                                        </Typography>
                                        &nbsp;
                                        <Typography variant="body1" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', color: "#667085", fontSize: "14px" }}>
                                            Industry
                                        </Typography>
                                    </div>
                                    <Progress percent={35} color='green' size='tiny'>
                                        <div className='text-flex exe-percent2' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Typography variant="body1" style={{ color: '#101828', fontFamily: 'Montserrat', fontWeight: 500, fontSize: "14px" }}>
                                                35%
                                            </Typography>
                                            <Typography variant="body1" style={{ color: '#667085', fontFamily: 'Montserrat', fontWeight: 400, fontSize: "10px", marginLeft: "10px" }}>
                                                Market Share
                                            </Typography>
                                        </div>
                                    </Progress>
                                </CardContent>
                            </Card>
                            <Card className='rgm-module'>
                                <CardContent className='card-content'>
                                    <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', color: "#101828", fontSize: "16px" }}>
                                            Volume
                                        </Typography>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', color: "##101828", fontSize: "17px" }}>
                                            2.3M
                                        </Typography>
                                    </div>
                                    <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography variant="h6" gutterBottom style={{ color: '#B42318', fontFamily: 'Montserrat', fontWeight: 600, fontSize: "36px" }}>
                                            -3%
                                        </Typography>
                                        <img src={IMAGECONFIG.VOLUMEPNG} />
                                    </div>
                                    <div className='content-flex' style={{ display: "flex", alignItems: "baseline", marginTop: "10px" }}>
                                        <Typography variant="body1" gutterBottom style={{ color: '#101828', fontFamily: 'Montserrat', fontWeight: 500, fontSize: "14px" }}>
                                            10%
                                        </Typography>
                                        &nbsp;
                                        <Typography variant="body1" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', color: "#667085", fontSize: "14px" }}>
                                            Industry
                                        </Typography>
                                    </div>
                                    <Progress percent={50} color='red' size='tiny'>
                                        <div className='text-flex' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Typography variant="body1" style={{ color: '#101828', fontFamily: 'Montserrat', fontWeight: 500, fontSize: "14px" }}>
                                                50%
                                            </Typography>
                                            <Typography variant="body1" style={{ color: '#667085', fontFamily: 'Montserrat', fontWeight: 400, fontSize: "10px", marginLeft: "10px" }}>
                                                Market Share
                                            </Typography>
                                        </div>
                                    </Progress>
                                </CardContent>
                            </Card>
                            <Card className='rgm-module'>
                                <CardContent className='card-content'>
                                    <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', color: "#101828", fontSize: "16px" }}>
                                            Transactions
                                        </Typography>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', color: "##101828", fontSize: "17px" }}>
                                            2.3M
                                        </Typography>
                                    </div>
                                    <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography variant="h6" gutterBottom style={{ color: '#027A48', fontFamily: 'Montserrat', fontWeight: 600, fontSize: "36px" }}>
                                            8%
                                        </Typography>
                                        <img src={IMAGECONFIG.TRANSACTIONPNG} />
                                    </div>
                                    <div className='content-flex' style={{ display: "flex", alignItems: "baseline", marginTop: "10px" }}>
                                        <Typography variant="body1" gutterBottom style={{ color: '#101828', fontFamily: 'Montserrat', fontWeight: 500, fontSize: "14px" }}>
                                            15%
                                        </Typography>
                                        &nbsp;
                                        <Typography variant="body1" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', color: "#667085", fontSize: "14px" }}>
                                            Industry
                                        </Typography>
                                    </div>
                                    <Progress percent={70} color='green' size='tiny'>
                                        <div className='text-flex' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Typography variant="body1" style={{ color: '#101828', fontFamily: 'Montserrat', fontWeight: 500, fontSize: "14px" }}>
                                                70%
                                            </Typography>
                                            <Typography variant="body1" style={{ color: '#667085', fontFamily: 'Montserrat', fontWeight: 400, fontSize: "10px", marginLeft: "10px" }}>
                                                Market Share
                                            </Typography>
                                        </div>
                                    </Progress>
                                </CardContent>
                            </Card>
                            <Card className='rgm-module'>
                                <CardContent className='card-content'>
                                    <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', color: "#101828", fontSize: "16px" }}>
                                            Profit
                                        </Typography>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', color: "##101828", fontSize: "17px" }}>
                                            2.3M
                                        </Typography>
                                    </div>
                                    <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography variant="h6" gutterBottom style={{ color: '#B42318', fontFamily: 'Montserrat', fontWeight: 600, fontSize: "36px" }}>
                                            -2%
                                        </Typography>
                                        <img src={IMAGECONFIG.PROFITPNG} />
                                    </div>
                                    <div className='content-flex' style={{ display: "flex", alignItems: "baseline", marginTop: "10px" }}>
                                        <Typography variant="body1" gutterBottom style={{ color: '#101828', fontFamily: 'Montserrat', fontWeight: 500, fontSize: "14px" }}>
                                            10%
                                        </Typography>
                                        &nbsp;
                                        <Typography variant="body1" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', color: "#667085", fontSize: "14px" }}>
                                            vs Last Year
                                        </Typography>
                                    </div>
                                    <Progress percent={65} color='red' size='tiny'>
                                        <div className='text-flex' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Typography variant="body1" style={{ color: '#101828', fontFamily: 'Montserrat', fontWeight: 500, fontSize: "14px" }}>
                                                65%
                                            </Typography>
                                            <Typography variant="body1" style={{ color: '#667085', fontFamily: 'Montserrat', fontWeight: 400, fontSize: "10px", marginLeft: "10px" }}>
                                                vs Plan
                                            </Typography>
                                        </div>
                                    </Progress>
                                </CardContent>
                            </Card>
                            <Card className='rgm-module'>
                                <CardContent className='card-content'>
                                    <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', color: "#101828", fontSize: "16px" }}>
                                            Pricing
                                        </Typography>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', color: "##101828", fontSize: "17px" }}>
                                            23/Unit
                                        </Typography>
                                    </div>
                                    <div className='content-flex exe-percent3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography variant="h6" gutterBottom style={{ color: '#027A48', fontFamily: 'Montserrat', fontWeight: 600, fontSize: "36px" }}>
                                            6%
                                        </Typography>
                                        <img src={IMAGECONFIG.PRICINGPNG} />
                                    </div>
                                    <div className='content-flex exe-percent4' style={{ display: "flex", alignItems: "baseline", marginTop: "10px" }}>
                                        <Typography variant="body1" gutterBottom style={{ color: '#101828', fontFamily: 'Montserrat', fontWeight: 500, fontSize: "14px" }}>
                                            5%
                                        </Typography>
                                        &nbsp;
                                        <Typography variant="body1" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', color: "#667085", fontSize: "14px" }}>
                                            vs Inflation
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
            <div className='component' style={{ padding: "0 10px 0 15px" }}>
                <div className='chart-tips'>
                    <div className='chart exe-growth'>
                        {waterfallChartOptions.map((chart_option, i) => {
                            return (
                                <div key={i} className="highcharts-figure">
                                    <div id="react-container">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={chart_option}
                                            allowChartUpdate={true}
                                        //updateArgs={[true, true, true]}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className='business-tips exe-driver'>
                        <Typography variant="h5" style={{ fontWeight: 600, fontFamily: 'Montserrat' }}>
                            DRIVERS / DRAGGERS
                        </Typography>
                        {/* <div className='driver-dagger' style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="h6" gutterBottom style={{ color: '#777777', fontFamily: 'Montserrat', fontWeight: 400, fontSize: "14px", flex: 0.55 }}>
                                YTD
                            </Typography>
                        </div> */}
                        <Divider style={{ margin: 0 }} />
                        <div className='driver-dagger' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px",overflow:"auto" }}>
                            <div className='company-percentage'>
                                <Typography variant="h6" gutterBottom style={{ color: '#777777', fontFamily: 'Montserrat', fontWeight: 700, fontSize: "14px", flex: 0.55 }}>
                                    YTD
                                </Typography>
                                <Typography variant="h3" gutterBottom style={{ color: '#069157', fontFamily: 'Montserrat', fontWeight: 700, fontSize: "28px" }}>
                                    +5%
                                </Typography>
                                <Typography variant="h6" gutterBottom style={{ color: '#777777', fontFamily: 'Montserrat', fontWeight: 400, fontSize: "14px" }}>
                                    WAL-MART
                                </Typography>
                            </div>
                            <div className='line-chart'>
                                {/* <div className='line-percentage' style={{ display: "flex", background: "#E8F9F5", borderRadius: "4px", width: "min-content" }}>
                                    <Icon name='triangle up' style={{ color: "rgba(22, 199, 154, 1)" }} />
                                    <Typography variant="body1" gutterBottom style={{ color: 'rgba(22, 199, 154, 1)', fontFamily: 'Montserrat', fontWeight: 500, fontSize: "14px" }}>
                                        23.5%
                                    </Typography>
                                </div>
                                <img src={IMAGECONFIG.LINECHARTPOSITIVE} style={{ marginTop: "10px" }} /> */}
                                {CONFIG.QoQ_GROWTH_CONFIG_1.map((chart_option, i) => {
                                    return (
                                        <div key={i} className="highcharts-figure">
                                            <div id="react-container">
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={chart_option}
                                                    allowChartUpdate={true}
                                                //updateArgs={[true, true, true]}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <Divider />
                        <div className='driver-dagger' style={{ display: "flex", justifyContent: "space-between", alignItems: "center",overflow:"auto" }}>
                            <div className='company-percentage'>
                                <Typography variant="h6" gutterBottom style={{ color: '#777777', fontFamily: 'Montserrat', fontWeight: 700, fontSize: "14px", flex: 0.55 }}>
                                    YTD
                                </Typography>
                                <Typography variant="h3" gutterBottom style={{ color: '#069157', fontFamily: 'Montserrat', fontWeight: 700, fontSize: "28px" }}>
                                    +9%
                                </Typography>
                                <Typography variant="h6" gutterBottom style={{ color: '#777777', fontFamily: 'Montserrat', fontWeight: 400, fontSize: "14px" }}>
                                    CALIMAY
                                </Typography>
                            </div>
                            <div className='line-chart'>
                                {/* <div className='line-percentage' style={{ display: "flex", background: "#E8F9F5", borderRadius: "4px", width: "min-content" }}>
                                    <Icon name='triangle up' style={{ color: "rgba(22, 199, 154, 1)" }} />
                                    <Typography variant="body1" gutterBottom style={{ color: 'rgba(22, 199, 154, 1)', fontFamily: 'Montserrat', fontWeight: 500, fontSize: "14px" }}>
                                        23.5%
                                    </Typography>
                                </div>
                                <img src={IMAGECONFIG.LINECHARTPOSITIVE} style={{ marginTop: "10px" }} /> */}
                                {CONFIG.QoQ_GROWTH_CONFIG_2.map((chart_option, i) => {
                                    return (
                                        <div key={i} className="highcharts-figure">
                                            <div id="react-container">
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={chart_option}
                                                    allowChartUpdate={true}
                                                //updateArgs={[true, true, true]}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <Divider />
                        <div className='driver-dagger' style={{ display: "flex", justifyContent: "space-between", alignItems: "center",overflow:"auto" }}>
                            <div className='company-percentage'>
                                <Typography variant="h6" gutterBottom style={{ color: '#777777', fontFamily: 'Montserrat', fontWeight: 700, fontSize: "14px", flex: 0.55 }}>
                                    YTD
                                </Typography>
                                <Typography variant="h3" gutterBottom style={{ color: '#D13535', fontFamily: 'Montserrat', fontWeight: 700, fontSize: "28px" }}>
                                    -3%
                                </Typography>
                                <Typography variant="h6" gutterBottom style={{ color: '#777777', fontFamily: 'Montserrat', fontWeight: 400, fontSize: "14px" }}>
                                    COSTCO
                                </Typography>
                            </div>
                            <div className='line-chart'>
                                {/* <div className='line-percentage' style={{ display: "flex", background: "#FFE7DF", borderRadius: "4px", width: "min-content" }}>
                                    <Icon name='triangle down' style={{ color: "rgba(235, 65, 65, 1)" }} />
                                    <Typography variant="body1" gutterBottom style={{ color: 'rgba(235, 65, 65, 1)', fontFamily: 'Montserrat', fontWeight: 500, fontSize: "14px" }}>
                                        5%
                                    </Typography>
                                </div>
                                <img src={IMAGECONFIG.LINECHARTNEGETIVE} style={{ marginTop: "10px" }} /> */}
                                {CONFIG.QoQ_GROWTH_CONFIG_3.map((chart_option, i) => {
                                    return (
                                        <div key={i} className="highcharts-figure">
                                            <div id="react-container">
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={chart_option}
                                                    allowChartUpdate={true}
                                                //updateArgs={[true, true, true]}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <Divider />
                    </div>
                </div>
            </div>
            <div className='first-section' style={{ marginBottom: "2%" }}>
                <div className='component exe-kpi'>
                    <Typography variant="h5" style={{ fontWeight: 600, fontFamily: 'Montserrat' }}>
                        My KPIs
                    </Typography>
                    <div className="container">
                        <div className="modules">
                            <div className="module">
                                <Card className='second-card'>
                                    <CardContent className='card-content'>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', fontSize: "12px", color: "#2D2A2B", width: "fit-content", background: "#FFCECE", border: "1px", padding: "4px 8px", borderRadius: "5px" }}>
                                                Warning
                                            </Typography>
                                            <Popup position='top center' trigger={<img src={IMAGECONFIG.WARNINGLOGO} />}>
                                                <Popup.Content>
                                                    <Typography variant='h6' style={{ color: "#AC2323", fontSize: "11px", fontWeight: 500, fontFamily: 'Montserrat' }}>
                                                        Losses from South Zone, Market shifts toward lower size
                                                    </Typography>
                                                </Popup.Content>
                                            </Popup>
                                        </div>
                                        <Typography variant="body1" gutterBottom style={{ color: '#595959', fontFamily: 'Montserrat', fontWeight: 400, fontSize: "12px", padding: "4px 0" }}>
                                            Promo ROI
                                        </Typography>
                                        <Typography variant="h6" style={{ fontWeight: 500, fontFamily: 'Montserrat', color: "#121212", fontSize: "24px" }}>
                                            Breakfast
                                        </Typography>
                                        <div className='content-flex exe-kpi' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="h6" style={{ fontWeight: 600, fontFamily: 'Montserrat', color: "#0B0E1E", fontSize: "14px", opacity: 0.5 }}>
                                                Planned
                                            </Typography>
                                            <Typography variant="h6" style={{ fontWeight: 700, fontFamily: 'Montserrat', color: "#0B0E1E", fontSize: "26px" }}>
                                               -5% 
                                            </Typography>
                                        </div>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="h6" style={{ fontWeight: 600, fontFamily: 'Montserrat', color: "#0B0E1E", fontSize: "14px", opacity: 0.5 }}>
                                                Actual
                                            </Typography>
                                            <Typography variant="h6" style={{ fontWeight: 700, fontFamily: 'Montserrat', color: "#AC2323", fontSize: "26px" }}>
                                                -8%
                                            </Typography>
                                        </div>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="h6" style={{ fontWeight: 600, fontFamily: 'Montserrat', color: "#0B0E1E", fontSize: "14px", opacity: 0.5 }}>
                                                Industry
                                            </Typography>
                                            <Typography variant="h6" style={{ fontWeight: 700, fontFamily: 'Montserrat', color: "#0B0E1E", fontSize: "26px" }}>
                                                -12%
                                            </Typography>
                                        </div>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                                            <Link href='#' color="inherit" style={{ color: '#2D2A2B', fontFamily: 'Montserrat', fontWeight: 700, textDecoration: 'underline' }}>
                                                View Tool
                                            </Link>
                                            <Typography variant='caption' gutterBottom display="block" style={{ color: '#595959', fontFamily: 'Montserrat', fontWeight: 400, fontSize: "12px" }}>
                                                Updated 1wk ago
                                            </Typography>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                            <div class="module">
                                <Card className='third-card'>
                                    <CardContent className='card-content'>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', fontSize: "12px", color: "#2D2A2B", width: "fit-content", background: "#DBF9F9", border: "1px", padding: "4px 8px", borderRadius: "5px" }}>
                                                Stable
                                            </Typography>
                                            <Popup position='top center' trigger={<img src={IMAGECONFIG.STABLELOGO} />}>
                                                <Popup.Content>
                                                    <Typography variant='h6' style={{ color: "#333333", fontSize: "11px", fontWeight: 500, fontFamily: 'Montserrat' }}>
                                                        Gaining from competition in mid-price segment range
                                                    </Typography>
                                                </Popup.Content>
                                            </Popup>
                                        </div>
                                        <Typography variant="body1" gutterBottom style={{ color: '#595959', fontFamily: 'Montserrat', fontWeight: 400, fontSize: "12px", padding: "4px 0" }}>
                                            Store RGM
                                        </Typography>
                                        <Typography variant="h6" style={{ fontWeight: 500, fontFamily: 'Montserrat', color: "#121212", fontSize: "24px" }}>
                                            Cereals
                                        </Typography>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="h6" style={{ fontWeight: 600, fontFamily: 'Montserrat', color: "#0B0E1E", fontSize: "14px", opacity: 0.5 }}>
                                                Planned
                                            </Typography>
                                            <Typography variant="h6" style={{ fontWeight: 700, fontFamily: 'Montserrat', color: "#0B0E1E", fontSize: "26px" }}>
                                                -4%
                                            </Typography>
                                        </div>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="h6" style={{ fontWeight: 600, fontFamily: 'Montserrat', color: "#0B0E1E", fontSize: "14px", opacity: 0.5 }}>
                                                Actual
                                            </Typography>
                                            <Typography variant="h6" style={{ fontWeight: 700, fontFamily: 'Montserrat', color: "#00A5A5", fontSize: "28px" }}>
                                                +1%
                                            </Typography>
                                        </div>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="h6" style={{ fontWeight: 600, fontFamily: 'Montserrat', color: "#0B0E1E", fontSize: "14px", opacity: 0.5 }}>
                                                Industry
                                            </Typography>
                                            <Typography variant="h6" style={{ fontWeight: 700, fontFamily: 'Montserrat', color: "#0B0E1E", fontSize: "26px" }}>
                                                -9%
                                            </Typography>
                                        </div>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                                            <Link href='#' color="inherit" style={{ color: '#2D2A2B', fontFamily: 'Montserrat', fontWeight: 700, textDecoration: 'underline' }}>
                                                View Tool
                                            </Link>
                                            <Typography variant='caption' gutterBottom display="block" style={{ color: '#595959', fontFamily: 'Montserrat', fontWeight: 400, fontSize: "12px" }}>
                                                Updated 1wk ago
                                            </Typography>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                            <div class="module">
                                <Card className='third-card'>
                                    <CardContent className='card-content'>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', fontSize: "12px", color: "#2D2A2B", width: "fit-content", background: "#DBF9F9", border: "1px", padding: "4px 8px", borderRadius: "5px" }}>
                                                Stable
                                            </Typography>
                                            <Popup position='top center' trigger={<img src={IMAGECONFIG.STABLELOGO} />}>
                                                <Popup.Content>
                                                    <Typography variant='h6' style={{ color: "#333333", fontSize: "11px", fontWeight: 500, fontFamily: 'Montserrat' }}>
                                                        Price premiumization gains by 2.5%
                                                    </Typography>
                                                </Popup.Content>
                                            </Popup>
                                        </div>
                                        <Typography variant="body1" gutterBottom style={{ color: '#595959', fontFamily: 'Montserrat', fontWeight: 400, fontSize: "12px", padding: "4px 0" }}>
                                            PVP Pricing
                                        </Typography>
                                        <Typography variant="h6" style={{ fontWeight: 500, fontFamily: 'Montserrat', color: "#121212", fontSize: "24px" }}>
                                            Snacks
                                        </Typography>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="h6" style={{ fontWeight: 600, fontFamily: 'Montserrat', color: "#0B0E1E", fontSize: "14px", opacity: 0.5 }}>
                                                Planned
                                            </Typography>
                                            <Typography variant="h6" style={{ fontWeight: 700, fontFamily: 'Montserrat', color: "#0B0E1E", fontSize: "26px" }}>
                                                -3%
                                            </Typography>
                                        </div>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="h6" style={{ fontWeight: 600, fontFamily: 'Montserrat', color: "#0B0E1E", fontSize: "14px", opacity: 0.5 }}>
                                                Actual
                                            </Typography>
                                            <Typography variant="h6" style={{ fontWeight: 700, fontFamily: 'Montserrat', color: "#00A5A5", fontSize: "26px" }}>
                                                +10%
                                            </Typography>
                                        </div>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="h6" style={{ fontWeight: 600, fontFamily: 'Montserrat', color: "#0B0E1E", fontSize: "14px", opacity: 0.5 }}>
                                                Industry
                                            </Typography>
                                            <Typography variant="h6" style={{ fontWeight: 700, fontFamily: 'Montserrat', color: "#0B0E1E", fontSize: "26px" }}>
                                                +4%
                                            </Typography>
                                        </div>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                                            <Link href='#' color="inherit" style={{ color: '#2D2A2B', fontFamily: 'Montserrat', fontWeight: 700, textDecoration: 'underline' }}>
                                                View Tool
                                            </Link>
                                            <Typography variant='caption' gutterBottom display="block" style={{ color: '#595959', fontFamily: 'Montserrat', fontWeight: 400, fontSize: "12px" }}>
                                                Updated 1wk ago
                                            </Typography>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                            <div class="module">
                                <Card className='third-card'>
                                    <CardContent className='card-content'>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', fontSize: "12px", color: "#2D2A2B", width: "fit-content", background: "#DBF9F9", border: "1px", padding: "4px 8px", borderRadius: "5px" }}>
                                                Stable
                                            </Typography>
                                            <Popup position='top center' trigger={<img src={IMAGECONFIG.STABLELOGO} />}>
                                                <Popup.Content>
                                                    <Typography variant='h6' style={{ color: "#333333", fontSize: "11px", fontWeight: 500, fontFamily: 'Montserrat' }}>
                                                        Improved supply efficiency in North zone
                                                    </Typography>
                                                </Popup.Content>
                                            </Popup>
                                        </div>
                                        <Typography variant="body1" gutterBottom style={{ color: '#595959', fontFamily: 'Montserrat', fontWeight: 400, fontSize: "12px", padding: "4px 0" }}>
                                            Demand Planning
                                        </Typography>
                                        <Typography variant="h6" style={{ fontWeight: 500, fontFamily: 'Montserrat', color: "#121212", fontSize: "24px" }}>
                                            Chips
                                        </Typography>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="h6" style={{ fontWeight: 600, fontFamily: 'Montserrat', color: "#0B0E1E", fontSize: "14px", opacity: 0.5 }}>
                                                Planned
                                            </Typography>
                                            <Typography variant="h6" style={{ fontWeight: 700, fontFamily: 'Montserrat', color: "#0B0E1E", fontSize: "26px" }}>
                                                -4%
                                            </Typography>
                                        </div>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="h6" style={{ fontWeight: 600, fontFamily: 'Montserrat', color: "#0B0E1E", fontSize: "14px", opacity: 0.5 }}>
                                                Actual
                                            </Typography>
                                            <Typography variant="h6" style={{ fontWeight: 700, fontFamily: 'Montserrat', color: "#00A5A5", fontSize: "26px" }}>
                                                +12%
                                            </Typography>
                                        </div>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="h6" style={{ fontWeight: 600, fontFamily: 'Montserrat', color: "#0B0E1E", fontSize: "14px", opacity: 0.5 }}>
                                                Industry
                                            </Typography>
                                            <Typography variant="h6" style={{ fontWeight: 700, fontFamily: 'Montserrat', color: "#0B0E1E", fontSize: "26px" }}>
                                                -7%
                                            </Typography>
                                        </div>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                                            <Link href='#' color="inherit" style={{ color: '#2D2A2B', fontFamily: 'Montserrat', fontWeight: 700, textDecoration: 'underline' }}>
                                                View Tool
                                            </Link>
                                            <Typography variant='caption' gutterBottom display="block" style={{ color: '#595959', fontFamily: 'Montserrat', fontWeight: 400, fontSize: "12px" }}>
                                                Updated 1wk ago
                                            </Typography>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                            <div class="module" style={{ width: "200px", height: "290px" }}>
                                <Card className='first-card'>
                                    <CardContent className='card-content' style={{ marginTop: "35%" }}>
                                        <AddOutlinedIcon className='add-widget-icon' />
                                        <Typography variant="h6" style={{ fontWeight: 600, color: "#FFFFFF", margin: "auto", fontFamily: 'Montserrat' }}>
                                            Add Widgets
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='component'>
                <div className='my-scenarios' style={{ marginBottom: "2%" }}>
                    <Typography variant="h5" style={{ fontWeight: 600, fontFamily: 'Montserrat' }}>
                        My Scenarios
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{ color: '#595959', fontFamily: 'Montserrat' }}>
                        Access your most important links at one go
                    </Typography>
                    <div className='data-navigation'>
                        <Typography variant="h6" onClick={() => setDataIndex(0)} className={dataIndex === 0 ? 'navlink active-navlink' : 'navlink'}>
                            All
                        </Typography>
                        <Typography variant="h6" onClick={() => setDataIndex(1)} className={dataIndex === 1 ? 'navlink active-navlink' : 'navlink'}>
                            Demand Planning
                        </Typography>
                        <Typography variant="h6" onClick={() => setDataIndex(2)} className={dataIndex === 2 ? 'navlink active-navlink' : 'navlink'}>
                            What If Promo
                        </Typography>
                        <Typography variant="h6" onClick={() => setDataIndex(3)} className={dataIndex === 3 ? 'navlink active-navlink' : 'navlink'}>
                            Market Share
                        </Typography>
                        <Typography variant="h6" onClick={() => setDataIndex(4)} className={dataIndex === 4 ? 'navlink active-navlink' : 'navlink'}>
                            Target Builder
                        </Typography>
                    </div>
                    <div className='my-scenarios-table'>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {CONFIG.MY_SCENARIOS_DATA[dataIndex].data.columns.map((col, i) => {
                                            return <TableCell className='table-cell' style={{ textAlign: i === 0 ? 'left' : 'center', fontFamily: 'Montserrat' }} key={i}>{col}</TableCell>
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {CONFIG.MY_SCENARIOS_DATA[dataIndex].data.values.map((val, j) => {
                                        return <TableRow key={j}>
                                            {val.map((v, k) => {
                                                return getTableCellData(v, k)
                                            })}
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
            <div className='component'>
                <div className='top-section-dashboard' style={{ marginBottom: "2%" }}>
                    <div className='first-section'>
                        <Typography variant="h5" style={{ fontWeight: 600, fontFamily: 'Montserrat' }}>
                            Most Viewed KPIs
                        </Typography>
                        <Typography variant="body1" gutterBottom style={{ color: '#595959', fontFamily: 'Montserrat' }}>
                            Have a look at the most viewed KPIs
                        </Typography>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            <Grid item xs={4}>
                                <Card className='second-card'>
                                    <CardContent className='card-content'>
                                        <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', fontSize: "12px", color: "#2D2A2B", width: "fit-content", background: "#FFCECE", border: "1px", padding: "4px 8px", borderRadius: "5px" }}>
                                            Warning
                                        </Typography>
                                        <Typography variant="body1" gutterBottom style={{ color: '#595959', fontWeight: 400, fontFamily: 'Montserrat', fontSize: "12px", padding: "4px 0" }}>
                                            PVP Price Increase
                                        </Typography>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="h6" style={{ fontWeight: 500, color: "rgba(45, 42, 43, 0.8)", fontFamily: 'Montserrat', fontSize: "20px" }}>
                                                Breakfast
                                            </Typography>
                                            <Typography variant="h6" style={{ fontWeight: 700, color: "#AC2323", fontFamily: 'Montserrat', fontSize: "28px" }}>
                                                -5%
                                            </Typography>
                                        </div>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                                            <Link href='#' color="inherit" style={{ color: '#2D2A2B', fontFamily: 'Montserrat', fontWeight: 550, textDecoration: 'underline' }}>
                                                View Tool
                                            </Link>
                                            <Typography variant='caption' gutterBottom display="block" style={{ color: '#595959', fontFamily: 'Montserrat' }}>
                                                Updated 1wk ago
                                            </Typography>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                <Card className='third-card'>
                                    <CardContent className='card-content'>
                                        <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', fontSize: "12px", color: "#2D2A2B", width: "fit-content", background: "#DBF9F9", border: "1px", padding: "4px 8px", borderRadius: "5px" }}>
                                            Stable
                                        </Typography>
                                        <Typography variant="body1" gutterBottom style={{ color: '#595959', fontWeight: 400, fontFamily: 'Montserrat', fontSize: "12px", padding: "4px 0" }}>
                                            PVP Price Increase
                                        </Typography>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="h6" style={{ fontWeight: 500, color: "rgba(45, 42, 43, 0.8)", fontFamily: 'Montserrat', fontSize: "20px" }}>
                                                Breakfast
                                            </Typography>
                                            <Typography variant="h6" style={{ fontWeight: 700, color: "#00A5A5", fontSize: "28px", fontFamily: 'Montserrat' }}>
                                                +8%
                                            </Typography>
                                        </div>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                                            <Link href='#' color="inherit" style={{ color: '#2D2A2B', fontWeight: 550, textDecoration: 'underline', fontFamily: 'Montserrat' }}>
                                                View Tool
                                            </Link>
                                            <Typography variant='caption' gutterBottom display="block" style={{ color: '#595959', fontFamily: 'Montserrat' }}>
                                                Updated 1wk ago
                                            </Typography>
                                        </div>

                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                <Card className='fourth-card'>
                                    <CardContent className='card-content'>
                                        <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 500, fontFamily: 'Montserrat', fontSize: "12px", color: "#2D2A2B", width: "fit-content", background: "#DBF9F9", border: "1px", padding: "4px 8px", borderRadius: "5px" }}>
                                            Stable
                                        </Typography>
                                        <Typography variant="body1" gutterBottom style={{ color: '#595959', fontWeight: 400, fontFamily: 'Montserrat', fontSize: "12px", padding: "4px 0" }}>
                                            PVP Price Increase
                                        </Typography>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="h6" style={{ fontWeight: 500, color: "rgba(45, 42, 43, 0.8)", fontFamily: 'Montserrat', fontSize: "20px" }}>
                                                Snacks
                                            </Typography>
                                            <Typography variant="h6" style={{ fontWeight: 700, color: "#00A5A5", fontSize: "28px", fontFamily: 'Montserrat' }}>
                                                +8%
                                            </Typography>
                                        </div>
                                        <div className='content-flex' style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                                            <Link href='#' color="inherit" style={{ color: '#2D2A2B', fontWeight: 550, textDecoration: 'underline', fontFamily: 'Montserrat' }}>
                                                View Tool
                                            </Link>
                                            <Typography variant='caption' gutterBottom display="block" style={{ color: '#595959', fontFamily: 'Montserrat' }}>
                                                Updated 1wk ago
                                            </Typography>
                                        </div>

                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            <div className='component'>
                <div className='my-scenarios'>
                    <Typography variant="h5" style={{ fontWeight: 600, fontFamily: 'Montserrat' }}>
                        Shared Scenarios
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{ color: '#595959', fontFamily: 'Montserrat' }}>
                        Easy access to the scenarios shared with you
                    </Typography>
                    <div className='data-navigation'>
                        <Typography variant="h6" onClick={() => setSharedDataIndex(0)} className={sharedDataIndex === 0 ? 'navlink active-navlink' : 'navlink'}>
                            All
                        </Typography>
                        <Typography variant="h6" onClick={() => setSharedDataIndex(1)} className={sharedDataIndex === 1 ? 'navlink active-navlink' : 'navlink'}>
                            Demand Planning
                        </Typography>
                        <Typography variant="h6" onClick={() => setSharedDataIndex(2)} className={sharedDataIndex === 2 ? 'navlink active-navlink' : 'navlink'}>
                            What If Promo
                        </Typography>
                        <Typography variant="h6" onClick={() => setSharedDataIndex(3)} className={sharedDataIndex === 3 ? 'navlink active-navlink' : 'navlink'}>
                            Market Share
                        </Typography>
                        <Typography variant="h6" onClick={() => setSharedDataIndex(4)} className={sharedDataIndex === 4 ? 'navlink active-navlink' : 'navlink'}>
                            Target Builder
                        </Typography>
                    </div>
                    <div className='my-scenarios-table'>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {/* background:"rgba(230, 236, 240, 0.4),borderBottom: "none"," */}
                                        {CONFIG.MY_SCENARIOS_DATA[sharedDataIndex].data.columns.map((col, i) => {
                                            return <TableCell className='table-cell' style={{ textAlign: i === 0 ? 'left' : 'center' }} key={i}>{col}</TableCell>
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {CONFIG.MY_SCENARIOS_DATA[sharedDataIndex].data.values.map((val, j) => {
                                        return <TableRow key={j}>
                                            {val.map((v, k) => {
                                                return getTableCellDataForSharedScenarios(v, k)
                                            })}
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
            {notesPanes ? <MyNotes open={notesPanes} closeNotesPanel={closeNotesPanel} /> : null}
        </div>
    )
}

export default ExclusiveDashboard