import React, { useEffect, useState } from 'react'
import { NavLink, Outlet, useLocation } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Collapse, ListItem, ListItemIcon, List, Typography, OutlinedInput, Divider,Link } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
// import { getPermission, getComingFrom, setComingFrom, setPermission } from "../../utils/services"
// import { getCookie } from '../../utils/getCookie'
import { useNavigate } from "react-router-dom"
import TOOLS_LIST from '../../Navigation/Navigation-config';
import { IMAGECONFIG } from '../../../config/image-config';
import Help_list from '../../Navigation/Help-config';
import { CONFIG } from '../../../config/config';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CircleIcon from '@mui/icons-material/Circle';
import './FocusTour.css'
import Tools from '../Tools/Tools';
import Caraousel from '../Carousel/Caraousel';
import TourModal from '../TourModal/TourModal';




// import Help_list from './Help-config';


const FocusTour = ({Path}) => {

    const [openModal,setopenModal] = useState(true);
    const [currentIndex,setCurrentIndex] = useState(0);
    const navigate = useNavigate();
    

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => (document.body.style.overflow = "scroll");
    },[])

    let activeClass = {
        color: "#3DD5C8",
        fontWeight: 400,
        
      };

    
    let nav_tour = {
        
            position: 'fixed',
            padding: '0.6% 2% 0.6% 2%',
            display: 'block',
            border:'2px solid white',
            borderRadius: '6px',
            color: "#3DD5C8",
            fontWeight: 700,
            backgroundColor: 'black',
            boxShadow :'0px 4px 15px #ffffff40',
            // transition: '1.3s ease'
             
         }
        
         

     const skipTour = () => {
        console.log('called '+ Path);
        navigate(Path)
    }

  return (
    <div className="main-content">
        
        <div className='sidebar-focus' style={{ width: "3.5vw" , minWidth: "50px" }}>  
            <div className='top-section' style={{ marginLeft: "-2px" }}>
            <div className='logo'><img src={IMAGECONFIG.LOGOWITHOUTTEXT} style={{ maxWidth: "150px" }} /></div>
            </div>
        
            <div className='list' style={{ marginTop: '50px' }}>
                {/* <FormControl className='search-field' sx={{
                    m: 1, width: '95%', "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                        borderColor: "rgba(237, 241, 244)",
                        borderRadius: "15px"
                        }
                    }
                }} variant="outlined">
                    <OutlinedInput
                    className='nav'
                    id="outlined-adornment-weight"
                    startAdornment={
                        <InputAdornment position="start">
                        <SearchIcon style={{ color: "white" }} />
                        </InputAdornment>
                    }
                    variant="standard"
                    color="warning"
                    aria-describedby="outlined-weight-helper-text"
                    /> </FormControl> */}

                <Typography variant="caption" display="block" gutterBottom style={{ color: '#FFFFFF', opacity: 0.6, display: 'none' , fontFamily: 'Montserrat' }}>
                Menu
            </Typography>
            <div style={{pointerEvents : 'none'}}>
            <NavLink
                to=''
                className='nav-container'
                // style={currentIndex === 2 ? activeClass : undefined}
                // style={({ isActive }) => (isActive ? activeClass : undefined)}
            >
             <BookmarksOutlinedIcon
                edge="start"
                fontSize='small'
                color="#FFFFFF"
                />
                <div className='name' style={{ display: 'none'}}>My Favourites</div>
            </NavLink>
            <NavLink
                to=''
                // style={currentIndex >= 1 && currentIndex <= 5 && currentIndex != 2 ? activeClass : undefined}
                className='nav-container'
            >
                <GridViewIcon
                fontSize='small'
                edge="start"
                color="#FFFFFF"
                justifyContent='space-evenly'
                />
                <div className='name name-execute' style={{display: 'none'} }> Executive Dashboard</div>
            </NavLink>
            <Divider />
            </div>
            <Typography variant="caption" display="block" gutterBottom style={{ color: '#FFFFFF', opacity: 0.6, display:  'none', fontFamily: 'Montserrat' }}>
                Tools by Category
            </Typography>
            <div style={{pointerEvents: 'none'}}>
            <List style={{paddingTop: '0px'}}>
                {TOOLS_LIST.map((tool, i) => {
                return <div key={i}>
                    <ListItem className='list-item'>
                    <div className='item-text' >
                        <ListItemIcon
                        className= 'link-all-tool'
                        edge="start"
                        sx={{
                            '.active &': {
                            color: "#3DD5C8",
                            }
                        }}>
                        {tool.icon}
                        </ListItemIcon>
                        <div style={{display:'none'}}>
                        <div className='name' >
                        {tool.title}
                        </div>
                        </div>
                    </div>
                    <ExpandMoreOutlinedIcon style={{ display: 'none' }} />
                    </ListItem>
                </div>
                })}
            </List>
            </div>
    
            <div className={'quick-link'}>
            <Divider />
            <Typography variant="caption" display="block" gutterBottom style={{ color: '#FFFFFF', opacity: 0.6, display: 'none', fontFamily: 'Montserrat' }}>
                Quick Links
            </Typography>          
            <a
                // style={{ cursor: "pointer" }}
            >
                {Help_list.icon}
                    <div style={{ display: 'none',width:'100%',justifyContent:'space-between' }}>{Help_list.title} 
                    <ExpandMoreOutlinedIcon style={{ display: 'none' }} /> 
                </div>
            </a>
            
            <a
                // style={{ cursor: "pointer" }}
            >
                <img src={IMAGECONFIG.LOGOUT} />
                <div className='name' style={{ display: 'none', marginLeft: "10px" }}>Logout</div>
                
            </a>
            </div>
        </div>

        </div>
            <div className='icons-focus' style={{ top: "5em", left:'2%',width:'30px' }}><KeyboardArrowRightIcon fontSize='large' /> 
            </div>
        
        <div className='content' style={{ width: "95vw" }}>
        {openModal ? <Caraousel Index={currentIndex} setIndex={setCurrentIndex} skipTour={skipTour} /> : null 
            }

            <div className='static-navbar' style={{ width: "95.5vw" }}>
                <div className='tool-data'>
                </div>
                <img  onClick={() => window.open('https://decisionpoint.ai/')} src={IMAGECONFIG.LOGO} alt='dp-logo' />
            </div>

            <div className='my-favourites' >
            
                <div className='component'>
                    <Typography variant="h4"  gutterBottom style={{ fontWeight: 600, fontFamily: 'Montserrat' }}>
                        <span >Key Business Metrics</span>
                    </Typography>
                    <div className='promotion-data metric' >
                        {CONFIG.PROMOTION_DATA.map((p_d, i) => {
                            return <div className='card' key={i} style={{ display: "flex", width: "25%", justifyContent: "space-between", padding: "10px" }}>
                                <div>
                                    <Typography variant="body1" gutterBottom style={{ color: '#595959', fontFamily: 'Montserrat' }}>
                                        {p_d.name}
                                    </Typography>
                                    <Typography variant="h3" className="percentage" gutterBottom style={{ fontWeight: 700, fontSize: '24px', color: '#000000', fontFamily: 'Montserrat' }}>
                                        {p_d.percentage}
                                    </Typography>
                                    <div className='comparision-percentage'>
                                        <ArrowUpwardIcon className='arrow-up-icon' /> &nbsp;
                                        <Typography variant="button" display="block" gutterBottom style={{ fontSize: "20px", color: '#12A7AE', fontWeight: 700, margin: 0, fontFamily: 'Montserrat' }}>
                                            {p_d.comparision_percentage}&nbsp;
                                        </Typography>
                                        <Typography variant="button" display="block" gutterBottom style={{ fontSize: "20px", color: 'rgba(89, 89, 89, 0.8)', margin: 0, fontFamily: 'Montserrat' }}>
                                            {p_d.comparision_value}
                                        </Typography>
                                    </div>
                                </div>
                                {i !== CONFIG.PROMOTION_DATA.length - 1 ? <Divider orientation="vertical" variant="middle" flexItem /> : null}
                            </div>
                            })}
                    </div>
                </div>
                
                <div className='recent-scenarios'>
                    <div className='component scene'>
                        <Typography variant="h6" gutterBottom style={{ fontWeight: 600, color: '#2D2A2B', fontFamily: 'Montserrat' }}>
                        Recently Created Scenarios 
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom style={{ color: 'rgba(89, 89, 89, 0.8)', fontFamily: 'Montserrat' }}>
                            Access your most important links at one go
                        </Typography>
                    </div>
                    <div className='glider-tour'>
                        {CONFIG.CARD_SLIDE_DATA.map((card, i) => {
                            return (
                                <div
                                    key={i}
                                    className="glide"
                                    // style={{ backgroundColor: CONFIG.CARD_SLIDE_COLORS[i] }}
                                    >
                                    <Typography variant="h6" gutterBottom style={{ fontWeight: 600, color: '#2D2A2B', fontFamily: 'Montserrat' }}>
                                        {card.header}
                                    </Typography>
                                    <List>
                                        {card.data.map((c_d, j) => {
                                            return <ListItem>
                                                <div className='pointer-flex'>
                                                    <div className='pointers' style={{ display: "flex", alignItems: 'center' }}>
                                                        <ListItemIcon className='bullet-icon'>
                                                            <CircleIcon style={{ fontSize: "0.5em", color: "black", marginRight: "5px" }} />
                                                        </ListItemIcon>
                                                        {c_d.point}
                                                    </div>
                                                    <div className='view-link'>
                                                        <Link href={c_d.link} color="inherit" style={{ color: '#2D2A2B', fontWeight: 550, textDecoration: 'underline', fontFamily: 'Montserrat' }}>
                                                            View Results
                                                        </Link>
                                                    </div>
                                                </div>
                                            </ListItem>
                                        })}
                                    </List>
                                </div>
                            );
                        })}
                    </div>
                    <div style={{ display: "flex", justifyContent: "right" }}>
                        <ArrowRightAltIcon id="moveBehind"  style={{ color: "grey",fontSize:"30px" }} />
                        <ArrowRightAltIcon id="moveAhead"  style={{ color:  "grey",fontSize:"30px" }} />
                    </div>

                </div>

                <Tools />
            </div>
 
        </div>


    </div>
  )
}

export default FocusTour